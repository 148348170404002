exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-accessibility-statement-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/accessibility-statement.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-accessibility-statement-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-acting-on-behalf-of-an-adult-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/acting-on-behalf-of-an-adult.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-acting-on-behalf-of-an-adult-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-acting-on-behalf-of-an-underaged-child-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/acting-on-behalf-of-an-underaged-child.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-acting-on-behalf-of-an-underaged-child-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-epilepsy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/epilepsy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-epilepsy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-movement-disorders-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/movement-disorders.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-movement-disorders-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-ms-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/ms.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-ms-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-non-invasive-neuromodulation-therapy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/non-invasive-neuromodulation-therapy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-non-invasive-neuromodulation-therapy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-privacy-policy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-privacy-policy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-sleep-apnea-and-respiratory-failure-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/sleep-apnea-and-respiratory-failure.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-sleep-apnea-and-respiratory-failure-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-sma-and-dmd-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/sma-and-dmd.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-sma-and-dmd-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-support-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/support.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-support-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-terms-of-use-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/en/terms-of-use.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-en-terms-of-use-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-alaikaisen-lapsen-puolesta-asiointi-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/alaikaisen-lapsen-puolesta-asiointi.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-alaikaisen-lapsen-puolesta-asiointi-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-epilepsia-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/epilepsia.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-epilepsia-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-kajoamaton-neuromodulaatiohoito-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/kajoamaton-neuromodulaatiohoito.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-kajoamaton-neuromodulaatiohoito-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-kayttoehdot-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/kayttoehdot.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-kayttoehdot-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-liikehairiot-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/liikehairiot.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-liikehairiot-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-ms-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/ms.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-ms-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-saavutettavuusseloste-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/saavutettavuusseloste.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-saavutettavuusseloste-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-sma-ja-dmd-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/sma-ja-dmd.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-sma-ja-dmd-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-taysi-ikaisen-henkilon-puolesta-asiointi-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/taysi-ikaisen-henkilon-puolesta-asiointi.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-taysi-ikaisen-henkilon-puolesta-asiointi-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-tietosuojaseloste-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/tietosuojaseloste.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-tietosuojaseloste-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-tuki-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/tuki.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-tuki-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-uniapnea-ja-hengitysvaje-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/fi/uniapnea-ja-hengitysvaje.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-fi-uniapnea-ja-hengitysvaje-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-anvandarvillkor-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/anvandarvillkor.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-anvandarvillkor-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-epilepsi-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/epilepsi.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-epilepsi-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-icke-invasiv-neuromoduleringsterapi-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/icke-invasiv-neuromoduleringsterapi.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-icke-invasiv-neuromoduleringsterapi-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-integritetspolicy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/integritetspolicy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-integritetspolicy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-kundsupport-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/kundsupport.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-kundsupport-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-ms-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/ms.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-ms-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-rorelsestorningar-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/rorelsestorningar.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-rorelsestorningar-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-sma-och-dmd-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/sma-och-dmd.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-sma-och-dmd-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-somnapne-och-respiratorisk-insufficiens-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/somnapne-och-respiratorisk-insufficiens.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-somnapne-och-respiratorisk-insufficiens-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-tillganglighetsuttalande-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/tillganglighetsuttalande.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-tillganglighetsuttalande-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-utratta-arenden-minderarigt-barn-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/utratta-arenden-minderarigt-barn.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-utratta-arenden-minderarigt-barn-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-utratta-arenden-myndig-person-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/working_dir/src/pages/se/utratta-arenden-myndig-person.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-pages-se-utratta-arenden-myndig-person-mdx" */),
  "component---src-pages-se-404-tsx": () => import("./../../../src/pages/se/404.tsx" /* webpackChunkName: "component---src-pages-se-404-tsx" */)
}

